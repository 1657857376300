// src/components/PartnershipSection.tsx

import React from 'react';
import ScrollAnimation from '../ScrollAnimation/ScrollAnimation';
import * as styles from './PartnershipSection.module.css';

type ColorType = 'primary' | 'secondary';

type PartnershipSectionProps = {
  imageSrc: string;
  imageAlt: string;
  title: string;
  description: string;
  sectionId: string;
  subTitle: string;
  colorType: ColorType;
  ctrLink: string;
  ctrText: string;
  founderName: string;
};

const PartnershipSectionRight: React.FC<PartnershipSectionProps> = ({
  imageSrc,
  imageAlt,
  title,
  description,
  sectionId,
  subTitle,
  colorType,
  ctrLink,
  ctrText,
  founderName,
}) => {
  let partnershipSectionClass = styles.partnershipSectionPrimary; // デフォルトのスタイル
  if (colorType === 'primary') {
    partnershipSectionClass = styles.partnershipSectionPrimary;
  } else if (colorType === 'secondary') {
    partnershipSectionClass = styles.partnershipSectionSecondary;
  }
  return (
    <ScrollAnimation>
      <section className={partnershipSectionClass} id={sectionId}>
        
        <div className={styles.title}>
          <h1>
            私たちは飲食店の<br/>
            デジタルマーケティングパートナーで<br/>
            あり続けます。
          </h1>
        </div>
        <div className={styles.text1}>
         <p>
            飲食業界では、ウェブを活用した集客方法が多様化しています。<br/>
            SEO対策、ネット広告、SNS、自社メディア、コンテンツマーケティングなど、<br />
            様々な手法があり、<span>集客数に上限</span>はありません。<br/>
            どの方法を選択し、どう取り組むかによって
            効果は大きく変わります。<br />
            適切な戦略を立てれば、<span>今の課題を一気に解決</span>できるかもしれません。<br /><br />
            私たちは、そんな飲食店のウェブマーケティングに関する課題を<br/>
            一緒に解決するパートナーです。<br />
            <span>ウェブで解決できる経営課題全般</span>に関してもサポートをさせていただきます。
          </p>
        </div>
        
        <div className={styles.text2}>
          <h2>ただ作るだけの<br/>ホームページ制作ではありません！</h2>
         <p>
            飲食店がホームページを作る目的は<br/>
            <span>新規顧客を獲得したい、売上を伸ばしたい</span><br/>
            といったことではないでしょうか。<br />
            私たちは、そうした<span>目的を達成するためのホームページ制作</span>を心がけています。
          </p>
        </div>

        <div className={styles.text3}>
          <h2>ウェブマーケティングの一元管理も<br/>可能です！</h2>
         <p>
           飲食店のウェブマーケティング全般を私たちにお任せいただけます。<br />
            そうすれば、経営者の皆様は本業である<span>店舗運営に専念</span>できます。<br />
            <span>選択と集中により、業務の効率化と売上アップ</span>
            を同時に実現できるでしょう。
          </p>
        </div>

        <div className={styles.text4}>
          <h2>名刺代わり・即納ホームページ制作！</h2>
         <p>
            集客以外でも、<span>すぐにでも名刺代わりのホームページが必要</span>だったり、<br/>
            既存顧客向けの簡易サイトが欲しい場合もあります。<br />
            私たちは、そうした<span>集客以外の目的のホームページ制作</span>
            にも対応しております。
          </p>
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default PartnershipSectionRight;
